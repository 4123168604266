import { Album } from "@/types/album";

export const albums: Album[] = [
    {
        "year": "2015",
        "composer": "Zaufke & Lund",
        "title": "Grimm!",
        "performers": [
            "UdK-Musicalstudenten und Orchester der Neuk. Oper",
            "H.-Peter Kirchberg, Mus. Leitung + Klavier"
        ],
        "orderInfo": "Best.-Nr. 090 (soundofmusic.de + NKO)",
        "coverImage": "/assets/albums/36.jpg"
    },
    {
        "year": "2014",
        "composer": "Zaufke & Lund",
        "title": "Schwestern im Geiste",
        "performers": [
            "UdK-Musicalstudenten und Orchester der Neuk.Oper",
            "H.-Peter Kirchberg, Mus. Leitung + Klavier"
        ],
        "orderInfo": "Best.-Nr. 088 (soundofmusic.de + NKO)",
        "coverImage": "/assets/albums/35.jpg"
    },
    {
        "year": "2013",
        "composer": "Lund + Böhmer",
        "title": "Stimmen im Kopf",
        "performers": [
            "UdK-Musicalstudenten und Rockband der Neuk.Oper",
            "H.-Peter Kirchberg, Mus. Leitung und Klavier"
        ],
        "orderInfo": "Best.-Nr. 073 (soundofmusic.de + NKO)",
        "coverImage": "/assets/albums/34.jpg"
    },
    {
        "year": "2012",
        "composer": "",
        "title": "Das Tanz-Salon-Orchester \"Non Plus Ultra\"",
        "performers": [
            "spielt in \"Pariser Besetzung\"",
            "Mus. Leitung: W. Radeke & H.-Peter Kirchberg"
        ],
        "orderInfo": "Best.-Nr. NCD 066",
        "coverImage": "/assets/albums/33.jpg"
    },
    {
        "year": "2011",
        "composer": "Zaufke und Lund",
        "title": "\"Mein Avatar und Ich\"",
        "performers": [
            "UDK-Musicalstudenten und Rockband der Neuk. Oper",
            "H.-Peter Kirchberg, musikalische Leitung und Klavier"
        ],
        "orderInfo": "Best.-Nr. SOM CD 056 + NKO",
        "coverImage": "/assets/albums/31.jpg"
    },
    {
        "year": "2009",
        "composer": "Lund / Böhmer",
        "title": "\"Leben ohne Chris\"",
        "performers": [
            "Solisten und Orchester der Neuköllner Oper",
            "H.-Peter Kirchberg, Mus.Leitung + Klavier"
        ],
        "orderInfo": "Best.-Nr.043 (soundofmusic.de und NKO)",
        "coverImage": "/assets/albums/30.jpg"
    },
    {
        "year": "2007",
        "composer": "Lund / Zaufke",
        "title": "\"KAUF DIR EIN KIND\"",
        "performers": [
            "Solisten und Orchester der Neuköllner Oper",
            "H.-Peter Kirchberg, Mus.Leitung + Klavier"
        ],
        "orderInfo": "Best.-Nr.031 (soundofmusic.de und NKO)",
        "coverImage": "/assets/albums/29.jpg"
    },
    {
        "year": "2006",
        "composer": "Lund / Zaufke",
        "title": "\"HELD MÜLLER\"",
        "performers": [
            "Solisten und Orchester der Neuköllner Oper",
            "H.-Peter Kirchberg, Mus.Leitung + Klavier"
        ],
        "orderInfo": "Best.-Nr.024 (soundofmusic.de und NKO)",
        "coverImage": "/assets/albums/28.jpg"
    },
    {
        "year": "2005",
        "composer": "Hugo Wolf",
        "title": "\"MÖRIKE-LIEDER\"",
        "performers": [
            "Instrumentiert für Orchester",
            "Grosses Sinfonie-Orchester der UdK Berlin",
            "Dirigent: H.-Peter Kirchberg"
        ],
        "orderInfo": "Archiv-Aufnahme",
        "coverImage": "/assets/albums/27.jpg"
    },
    {
        "year": "2005",
        "composer": "Lund / Zaufke",
        "title": "\"Letterland\"",
        "performers": [
            "Shakespeare's \"Falstaff\" heute",
            "Solisten und Orchester der Neuköllner Oper",
            "H.-Peter Kirchberg, M. Leitung + Klavier",
            "Gayle Tufts, Bonus-Track"
        ],
        "orderInfo": "Best.-Nr.SOM CD 022 (im Handel und NKO)",
        "coverImage": "/assets/albums/26.jpg"
    },
    {
        "year": "2004",
        "composer": "",
        "title": "... kommt jetzt der Schwan ?",
        "performers": [
            "Anja-Susann Hammer, Violoncello",
            "und H.-Peter Kirchberg, Klavier",
            "in einem Moderations-Konzert"
        ],
        "orderInfo": "Archiv-Aufnahme",
        "coverImage": "/assets/albums/22.jpg"
    },
    {
        "year": "2004",
        "composer": "",
        "title": "Berliner Salon",
        "performers": [
            "Das BerlinerSalonOrchester und Winnie Böwe (Gesang) musizieren Werke von Benatzky, Lincke, Lortzing, Rubinstein u.a.",
            "Musik. Leitung / Klavier: H.-Peter Kirchberg, live-Mitschnitt aus der Berliner Philharmonie"
        ],
        "orderInfo": "",
        "coverImage": "/assets/albums/21.jpg"
    },
    {
        "year": "2004",
        "composer": "",
        "title": "Kirchenmusikal. Gottesdienst",
        "performers": [
            "H.-Peter Kirchberg spielt Werke von J.S.Bach",
            "an der berühmten Lütkemüller Orgel von St. Marien in Röbel / Müritz",
            "und singt mit ehemaligen Thomanern"
        ],
        "orderInfo": "Archiv-Aufnahmen Vol.1-4 (2001-2004)",
        "coverImage": "/assets/albums/20.jpg"
    },
    {
        "year": "2004",
        "composer": "Lund / Ramdohr",
        "title": "Die traurige Ballade von John Merrick, genannt: Der Elefantenmensch",
        "performers": [
            "Solisten und Orchester der Neuköllner Oper",
            "H.-Peter Kirchberg, Musikal. Leitung + Klavier"
        ],
        "orderInfo": "Archiv-Produktion",
        "coverImage": "/assets/albums/23.jpg"
    },
    {
        "year": "2003",
        "composer": "Lund / Zaufke",
        "title": "Elternabend-Das Musical",
        "performers": [
            "Solisten und Orchester der Neuköllner Oper",
            "Hans-Peter Kirchberg, Musikal. Leit. + Klavier",
            "Nicolai Thaerichen, Klavier"
        ],
        "orderInfo": "Bestell-Nr. CFM 26 (im Handel und Neuk. Oper)",
        "coverImage": "/assets/albums/18.jpg"
    },
    {
        "year": "2003",
        "composer": "",
        "title": "Christmas Meditation",
        "performers": [
            "H.-Peter Kirchberg (Orgel/Cembalo) mit dem Neuen Berliner Kammerorchester"
        ],
        "orderInfo": "Im Handel erhältlich unter: EXCLUSIV 222 520",
        "coverImage": "/assets/albums/17.jpg"
    },
    {
        "year": "2002",
        "composer": "Musik in Deutschland",
        "title": "Operette & Musical vol. 2",
        "performers": [
            "u.a. mit Böhmers \"Wunder von Neukölln\"",
            "Solisten und Orchester der Neuköllner Oper",
            "Hans-Peter Kirchberg, Musikalische Leitung"
        ],
        "orderInfo": "im Handel erhältlich unter: RCA Read Seal/Ariola Classics 74321 73546 2",
        "coverImage": "/assets/albums/25.jpg"
    },
    {
        "year": "2002",
        "composer": "Musik in Deutschland",
        "title": "Operette & Musical vol. 1",
        "performers": [
            "u.a. Natschinski's \"Messeschlager Gisela\"",
            "Solisten und Orchester der Neuköllner Oper",
            "Hans-Peter Kirchberg, Musikalische Leitung"
        ],
        "orderInfo": "im Handel erhältlich unter: RCA Red Seal/ Ariola Classics 74321 73545 2",
        "coverImage": "/assets/albums/19.jpg"
    },
    {
        "year": "2002",
        "composer": "Böhmer, Radeke, Ramdohr, Zaufke",
        "title": "25 Jahre Neuköllner Oper",
        "performers": [
            "Solisten und Orchester der Neuköllner Oper",
            "Dirigenten: Hans-Peter Kirchberg + Winfried Radeke"
        ],
        "orderInfo": "Archiv-Produktion",
        "coverImage": "/assets/albums/15.jpg"
    },
    {
        "year": "2002",
        "composer": "Schwemmer",
        "title": "Angela – Eine Nationaloper",
        "performers": [
            "Live-Mitschnitt der Oper über Angela Merkel aus dem U-Bahnhof Reichstag",
            "Solisten und Orchester der Neuköllner Oper"
        ],
        "orderInfo": "Archiv-Produktion",
        "coverImage": "/assets/albums/14.jpg"
    },
    {
        "year": "2002",
        "composer": "Sondheim",
        "title": "Assassins-Attentäter",
        "performers": [
            "Musical-Studenten der UdK Berlin und Orchester der Neuköllner Oper",
            "Hans-Peter Kirchberg, Leitung und Klavier"
        ],
        "orderInfo": "Archiv-Produktion",
        "coverImage": "/assets/albums/13.jpg"
    },
    {
        "year": "2001",
        "composer": "Lund/Zaufke",
        "title": "Cinderella passt was nicht",
        "performers": [
            "Ein ziemlich schräges Märchen-Musical",
            "Solisten und Orchester der Neuköllner Oper",
            "Hans-Peter Kirchberg, Leitung und Klavier"
        ],
        "orderInfo": "Im Handel und über Neuköllner Oper erhältlich",
        "coverImage": "/assets/albums/12.jpg"
    },
    {
        "year": "2001",
        "composer": "Lund/Böhmer",
        "title": "Love Bite – Biss ins Herz",
        "performers": [
            "Musical-Studenten der UdK Berlin und Orchester der Neuköllner Oper",
            "Hans-Peter Kirchberg, Leitung und Klavier"
        ],
        "orderInfo": "Über Neuköllner Oper erhältlich",
        "coverImage": "/assets/albums/11.jpg"
    },
    {
        "year": "2000",
        "composer": "Benda, J.Chr.Bach, Stamitz, Gossec, Weber",
        "title": "Märsche & Bläsermusik",
        "performers": [
            "Bläser des Rundfunk-Sinfonie-Orchesters Berlin",
            "Dirigenten: Hans-Peter Kirchberg und Sebastian Weigle"
        ],
        "orderInfo": "Im Handel erhältlich (Capriccio 10499)",
        "coverImage": "/assets/albums/16.jpg"
    },
    {
        "year": "2000",
        "composer": "Schubert",
        "title": "Die Winterreise",
        "performers": [
            "Eberhard Kirchberg, Bariton",
            "Hans-Peter Kirchberg, Klavier"
        ],
        "orderInfo": "Archiv-Produktion",
        "coverImage": "/assets/albums/10.jpg"
    },
    {
        "year": "2000",
        "composer": "",
        "title": "Dirigent Hans-Peter Kirchberg",
        "performers": [
            "u.a. aus Grieg \"Peer-Gynt-Suite\", Schumann \"Frühlingssinfonie\", Puccini \"La Bohème\""
        ],
        "orderInfo": "Archiv-Produktion",
        "coverImage": "/assets/albums/09.jpg"
    },
    {
        "year": "2000",
        "composer": "Bürkholz",
        "title": "Rheinsberger Maskerade",
        "performers": [
            "Solisten und Orchester der Musikakademie Rheinsberg",
            "Hans-Peter Kirchberg, Leitung"
        ],
        "orderInfo": "Archiv-Produktion",
        "coverImage": "/assets/albums/08.jpg"
    },
    {
        "year": "1999",
        "composer": "Lund/Böhmer",
        "title": "Das Wunder von Neukölln",
        "performers": [
            "Solisten und Orchester der Neuköllner Oper",
            "Hans-Peter Kirchberg, Leitung und Klavier"
        ],
        "orderInfo": "Im Handel und über Neuköllner Oper erhältlich",
        "coverImage": "/assets/albums/07.jpg"
    },
    {
        "year": "1998",
        "composer": "Brecht/Eisler",
        "title": "Die Mutter (Urfassung)",
        "performers": [
            "Eberhard Kirchberg, Bariton",
            "Garbriele Heinz, Pelagea Wlassowa",
            "Hans-Peter Kirchberg, Leitung und Klavier"
        ],
        "orderInfo": "Über theater89 erhältlich",
        "coverImage": "/assets/albums/06.jpg"
    },
    {
        "year": "1998",
        "composer": "Natschinski (arr.Schwemmer)",
        "title": "Gisela",
        "performers": [
            "Erfolgs-Produktion der Neuköllner Oper",
            "Solisten und Orchester der Neuköllner Oper",
            "Hans-Peter Kirchberg, Leitung und Klavier"
        ],
        "orderInfo": "Über Neuköllner Oper erhältlich",
        "coverImage": "/assets/albums/05.jpg"
    },
    {
        "year": "1996",
        "composer": "Paul Lincke",
        "title": "Folies Bergère",
        "performers": [
            "Salonorchester NonPlusUltra",
            "Winfried Radeke, Leitung",
            "Hans-Peter Kirchberg, Klavier"
        ],
        "orderInfo": "Über Neuköllner Oper erhältlich",
        "coverImage": "/assets/albums/04.jpg"
    },
    {
        "year": "1995",
        "composer": "Korngold",
        "title": "Between Two Worlds",
        "performers": [
            "Rundfunk-Sinfonieorchester Berlin",
            "John Mauceri, Leitung",
            "Hans-Peter Kirchberg, Orgel"
        ],
        "orderInfo": "Im Handel erhältlich (Decca 444 170-2)",
        "coverImage": "/assets/albums/03.jpg"
    },
    {
        "year": "1993",
        "composer": "Händel",
        "title": "Ezio",
        "performers": [
            "Solisten und Orchester der Berliner Kammeroper",
            "Brymor Llewelyn Jones, Leitung",
            "Hans-Peter Kirchberg, Cembalo"
        ],
        "orderInfo": "Im Handel erhältlich (DS 1051-2)",
        "coverImage": "/assets/albums/02.jpg"
    },
    {
        "year": "1992",
        "composer": "Manfredini, Corelli, Händel, Locatelli",
        "title": "Weihnachtskonzerte",
        "performers": [
            "Neues Berliner Kammerorchester",
            "Michael Erxleben, Leitung",
            "Hans-Peter Kirchberg, Cembalo & Orgel"
        ],
        "orderInfo": "Im Handel erhältlich (Capriccio 10442)",
        "coverImage": "/assets/albums/01.jpg"
    },
    {
        "year": "1990",
        "composer": "Prokofjew",
        "title": "Romeo und Julia",
        "performers": [
            "Deutsch-Sowjetische Junge Philharmonie",
            "Musikal. Assistenz / Orgel: H.-Peter Kirchberg",
            "Dirigent: Mstislaw Rostropowitsch"
        ],
        "orderInfo": "Archiv-Produktion, live-Mitschnitt 20.07.90",
        "coverImage": "/assets/albums/24.jpg"
    }
];
