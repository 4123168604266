export interface PictureItem {
  src: string;
  year: string;
}

export const pianistImages: PictureItem[] = [
  {
    src: "/assets/pictures/pianist/01.jpg",
    year: "1962"
  },
  {
    src: "/assets/pictures/pianist/02.jpg",
    year: "1970"
  },
  {
    src: "/assets/pictures/pianist/03.jpg",
    year: "1972"
  },
  {
    src: "/assets/pictures/pianist/04.jpg",
    year: "1973"
  },
  {
    src: "/assets/pictures/pianist/05.jpg",
    year: "1974"
  },
  {
    src: "/assets/pictures/pianist/20.jpg",
    year: "1991"
  },
  {
    src: "/assets/pictures/pianist/24.jpg",
    year: "2000"
  },
  {
    src: "/assets/pictures/pianist/25.jpg",
    year: "2001"
  },
  {
    src: "/assets/pictures/pianist/35.jpg",
    year: "2004"
  }
];

export const conductorImages: PictureItem[] = [
  {
    src: "/assets/pictures/dirigent/08.jpg",
    year: "1979"
  },
  {
    src: "/assets/pictures/dirigent/11.jpg",
    year: "1983"
  },
  {
    src: "/assets/pictures/dirigent/12.jpg",
    year: "1983"
  },
  {
    src: "/assets/pictures/dirigent/22.jpg",
    year: "1985"
  },
  {
    src: "/assets/pictures/dirigent/26.jpg",
    year: "1986"
  },
  {
    src: "/assets/pictures/dirigent/27.jpg",
    year: "1987"
  },
  {
    src: "/assets/pictures/dirigent/28.jpg",
    year: "1987"
  },
  {
    src: "/assets/pictures/dirigent/29.jpg",
    year: "1987"
  },
  {
    src: "/assets/pictures/dirigent/30.jpg",
    year: "1993"
  },
  {
    src: "/assets/pictures/dirigent/31.jpg",
    year: "1997"
  },
  {
    src: "/assets/pictures/dirigent/34.jpg",
    year: "2002"
  },
  {
    src: "/assets/pictures/dirigent/37.jpg",
    year: "2004"
  },
  {
    src: "/assets/pictures/dirigent/38.jpg",
    year: "2005"
  },
  {
    src: "/assets/pictures/dirigent/39.jpg",
    year: "2008"
  },
  {
    src: "/assets/pictures/dirigent/42.jpg",
    year: "2009"
  },
  {
    src: "/assets/pictures/dirigent/43.jpg",
    year: "2015"
  },
  {
    src: "/assets/pictures/dirigent/44.jpg",
    year: "2015"
  }
];

export const importantMeetingsImages: PictureItem[] = [
  {
    src: "/assets/pictures/wichtige-begegnungen/06.jpg",
    year: "1975"
  },
  {
    src: "/assets/pictures/wichtige-begegnungen/07.jpg",
    year: "1978"
  },
  {
    src: "/assets/pictures/wichtige-begegnungen/10.jpg",
    year: "1983"
  },
  {
    src: "/assets/pictures/wichtige-begegnungen/13.jpg",
    year: "1988"
  },
  {
    src: "/assets/pictures/wichtige-begegnungen/15.jpg",
    year: "1989"
  },
  {
    src: "/assets/pictures/wichtige-begegnungen/16.jpg",
    year: "1989"
  },
  {
    src: "/assets/pictures/wichtige-begegnungen/18.jpg",
    year: "1990"
  },
  {
    src: "/assets/pictures/wichtige-begegnungen/19.jpg",
    year: "1990"
  },
  {
    src: "/assets/pictures/wichtige-begegnungen/21.jpg",
    year: "1992"
  },
  {
    src: "/assets/pictures/wichtige-begegnungen/23.jpg",
    year: "1999"
  },
  {
    src: "/assets/pictures/wichtige-begegnungen/32.jpg",
    year: "2003"
  },
  {
    src: "/assets/pictures/wichtige-begegnungen/33.jpg",
    year: "2004"
  },
  {
    src: "/assets/pictures/wichtige-begegnungen/36.jpg",
    year: "2004"
  },
  {
    src: "/assets/pictures/wichtige-begegnungen/40.jpg",
    year: "2014"
  },
  {
    src: "/assets/pictures/wichtige-begegnungen/41.jpg",
    year: "2015"
  }
];

export const pressImages: PictureItem[] = [
  {
    src: "/assets/pictures/presse/presse5.jpg",
    year: ""
  },
  {
    src: "/assets/pictures/presse/presse6.jpg",
    year: ""
  },
  {
    src: "/assets/pictures/presse/presse7.jpg",
    year: ""
  },
  {
    src: "/assets/pictures/presse/presse8.jpg",
    year: ""
  }
];