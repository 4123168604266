import Navbar from "@/components/Navbar";
import Hero from "@/components/Hero";
import Welcome from "@/components/Welcome";
import Footer from "@/components/Footer";

const Index = () => {
  return (
    <div className="min-h-screen bg-[#EDE9CE]">
      <Navbar />
      <Hero />
      <Welcome />
      <Footer />
    </div>
  );
};

export default Index;